/* eslint-disable no-console */
import { FC, useMemo } from "react";

import { ChakraProvider } from "@chakra-ui/react";

import type { AppProps } from "next/app";
import Head from "next/head";

import { getCustomTheme } from "theme";
import { themeColors, semanticTokens } from "themes";
import { RudderStackScript } from "app-head";

import UserPilot from "@components/UserPilot";

import { descriptionMappings, titleMappings } from "@constants/meta";
import AnalyticsMapping from "@constants/analytics_mapping.json";
import CMSJson from "@constants/cms.json";

import { DappSDKProvider } from "dapp-sdk-v2";

import ChatBot from "@components/ChatBot/ChatBot";
import Banner from "@components/Banner";

import "../styles/globals.scss";
import { getBaseUrl } from "@utils/common";
import dynamic from "next/dynamic";

const token = process.env.NEXT_PUBLIC_TOKEN || "";

const PolygonDisclaimer = dynamic(
  () =>
    import("@components/Disclaimer/PolygonDisclaimer").then(
      (mod) => mod.PolygonDisclaimer
    ),
  {
    ssr: false,
  }
);

const DefaultHead = () => {
  return (
    <Head>
      <title>{titleMappings[token?.toUpperCase()]}</title>
      <meta
        name="description"
        content={descriptionMappings[token?.toUpperCase()]}
      />
      <link rel="icon" href="./favicon.ico" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="./favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="./favicon-16x16.png"
      />
      <meta name="robots" content="index, follow" />
      <link rel="manifest" href="./manifest.json" />
      <link rel="apple-touch-icon" href="./apple-touch-icon.png" />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Light.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Regular.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Medium.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-SemiBold.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Bold.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href={`${getBaseUrl()}assets/fonts/Gilroy-Heavy.woff`}
        as="font"
        type="font/woff"
        crossOrigin="anonymous"
      />

      <style
        dangerouslySetInnerHTML={{
          __html: `
            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Light.woff") format("woff");
              font-weight: 300;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Regular.woff") format("woff");
              font-weight: 400;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Medium.woff") format("woff");
              font-weight: 500;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-SemiBold.woff") format("woff");
              font-weight: 600;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Bold.woff") format("woff");
              font-weight: 700;
              font-style: normal;
            }

            @font-face {
              font-family: "Gilroy";
              src: url("${getBaseUrl()}assets/fonts/Gilroy-Heavy.woff") format("woff");
              font-weight: 800;
              font-style: italic;
            }
          `,
        }}
      />

      <RudderStackScript
        basePath={getBaseUrl()}
        fileName={AnalyticsMapping["fileName"]}
      />
    </Head>
  );
};

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const wallets = useMemo(() => {
    const jsonData: any = { ...CMSJson };
    return (
      jsonData?.wallets?.data?.map((wallletDetail: any) => {
        return {
          key: wallletDetail?.attributes?.key,
          name: wallletDetail?.attributes?.name,
          sortKey:
            wallletDetail?.attributes?.sort_key || Number.MAX_SAFE_INTEGER,
        };
      }) || []
    );
  }, []);

  return (
    <>
      <DefaultHead />
      <ChakraProvider theme={getCustomTheme(themeColors, semanticTokens)}>
        <DappSDKProvider
          wallets={wallets.sort(
            (a: { sortKey: number }, b: { sortKey: number }) =>
              a.sortKey - b.sortKey
          )}
        >
          <Banner Component={Component} />
          <UserPilot />
          <ChatBot />
          <Component {...pageProps} />
          {token === "POLYGON" && <PolygonDisclaimer />}
        </DappSDKProvider>
      </ChakraProvider>
    </>
  );
};

export default App;
